import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TableSortLabel,
} from "@mui/material";

const ProjectTable = ({
  projects,
  page,
  rowsPerPage,
  totalCount,
  onPageChange,
  onRowsPerPageChange,
  orderBy,
  order,
  onSortChange,
}) => {
  const navigate = useNavigate();

  const formatDate = useCallback(
    (dateString) =>
      dateString ? new Date(dateString).toLocaleDateString() : "N/A",
    []
  );

  const capitalizeFirstLetter = useCallback(
    (val) => String(val).charAt(0).toUpperCase() + String(val).slice(1),
    []
  );

  // Status color mapping
  const statusColors = useMemo(
    () => ({
      "submitted for processing": "gray",
      "available for download": "green",
    }),
    []
  );

  const handleSort = (column) => {
    const isAsc = orderBy === column && order === "asc";
    onSortChange(column, isAsc ? "desc" : "asc");
  };

  const columns = useMemo(
    () => [
      { id: "name", label: "Project Name" },
      { id: "number_of_files", label: "No of Files" },
      { id: "created_at", label: "Created On" },
      { id: "expected_due_date", label: "Due Date" },
      { id: "status", label: "Status" },
      { id: "total_metric_tonnes", label: "Processed MT" },
      { id: "total_wastage", label: "Waste MT" },
      { id: "waste_percentage", label: "Waste %" },
    ],
    []
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={col.id}
                  sx={{ fontWeight: "600", background: "#f8f9fa" }}
                >
                  <TableSortLabel
                    active={orderBy === col.id}
                    direction={orderBy === col.id ? order : "asc"}
                    onClick={() => handleSort(col.id)}
                  >
                    {col.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.length ? (
              projects.map((project, index) => {
                const isClickable = project.status === "available for download";

                return (
                  <TableRow
                    key={project.id || index}
                    onClick={() =>
                      isClickable && navigate(`/project/${project.id}`)
                    }
                    sx={{
                      cursor: isClickable ? "pointer" : "default",
                      "&:hover": {
                        backgroundColor: isClickable
                          ? "rgba(0, 0, 0, 0.04)"
                          : "inherit",
                      },
                    }}
                  >
                    <TableCell>{project.name || "N/A"}</TableCell>
                    <TableCell>{project.number_of_files || 0}</TableCell>
                    <TableCell>{formatDate(project.created_at)}</TableCell>
                    <TableCell>
                      {formatDate(project.expected_due_date)}
                    </TableCell>
                    <TableCell
                      sx={{ color: statusColors[project.status] || "black" }}
                    >
                      {capitalizeFirstLetter(project.status) || "N/A"}
                    </TableCell>
                    <TableCell>{project.total_metric_tonnes}</TableCell>
                    <TableCell>{project.total_wastage}</TableCell>
                    <TableCell>{project.waste_percentage}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No projects available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
};

export default React.memo(ProjectTable);
