import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, Tab, Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Error from "../components/Error";
import Loading from "../components/Loading";
import Cuts from "../components/Cuts";
import StockSummary from "../components/StockSummary";
import MultiStockSummary from "../components/MultiStockSummary";
import MultiStockCuts from "../components/MultiStockCuts";
import { projectService } from "../api/projectService";

const ProjectPage = () => {
  const [projectStockSummary, setProjectStockSummary] = useState(null);
  const [projectMultiStockSummary, setProjectMultiStockSummary] =
    useState(null);
  const [selectedStockWidth, setSelectedStockWidth] = useState("total");
  const [selectedMultiStockWidth, setSelectedMultiStockWidth] =
    useState("total");
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadProjectData = async () => {
      try {
        setLoading(true);
        const [stockSummary, multiStockSummary] = await Promise.all([
          projectService.getProjectStockSummary(projectId),
          projectService.getProjectMultiStockSummary(projectId),
        ]);

        setProjectStockSummary(stockSummary);
        setProjectMultiStockSummary(multiStockSummary);
        setSelectedStockWidth("total");
        setSelectedMultiStockWidth("total");
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadProjectData();
  }, [projectId]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (!projectStockSummary || projectStockSummary.project_summary.length === 0) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        Project stock summary not available.
      </div>
    );
  }

  return (
    <div className="w-full mx-auto">
      <div className="flex w-full items-center justify-between mb-8">
        <h2 className="text-4xl text-gray-700 font-semibold">
          {projectStockSummary.name}
        </h2>

        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => navigate("/dashboard")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
        >
          <Tab label="Stock Summary" />
          <Tab label="Cuts" />
          <Tab label="Multi Stock Summary" />
          <Tab label="Multi Stock Cuts" />
        </Tabs>
      </Box>

      {activeTab === 0 && (
        <StockSummary
          projectData={projectStockSummary}
          selectedWidth={selectedStockWidth}
          setSelectedWidth={setSelectedStockWidth}
        />
      )}
      {activeTab === 1 && <Cuts />}
      {activeTab === 2 && (
        <MultiStockSummary
          projectData={projectMultiStockSummary}
          selectedWidth={selectedMultiStockWidth}
          setSelectedWidth={setSelectedMultiStockWidth}
        />
      )}
      {activeTab === 3 && <MultiStockCuts />}
    </div>
  );
};

export default ProjectPage;
