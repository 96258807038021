import React, { useMemo } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { Select, MenuItem } from "@mui/material";

const ProjectDetails = ({ details }) => (
  <div className="pt-4 pb-5 px-6 bg-gray-100 rounded-md">
    <span className="font-semibold text-xl leading-10">Project Details</span>
    <div className="space-y-2 mt-4">
      {details.map(([label, value]) => (
        <div className="flex justify-between" key={label}>
          <span>{label}:</span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  </div>
);

const MetricDetails = ({
  title,
  metrics,
  selectedWidth,
  onChange,
  options,
}) => (
  <div className="pt-4 pb-5 px-6 bg-gray-100 rounded-md">
    <div className="flex justify-between items-center mb-4">
      <span className="font-semibold text-xl">
        {selectedWidth === "total" ? "Overall" : `${selectedWidth}mm`} (Pieces)
      </span>
      <Select
        value={selectedWidth}
        onChange={onChange}
        className="h-10 bg-white"
        size="small"
        autoWidth
        displayEmpty
      >
        {options.map((item) => (
          <MenuItem key={item.width || "default"} value={item.width}>
            {item.width === "total" ? "Overall" : `${item.width}mm`}
          </MenuItem>
        ))}
      </Select>
    </div>
    <div className="space-y-2">
      {metrics.map(([label, value]) => (
        <div className="flex justify-between" key={label}>
          <span>{label}:</span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  </div>
);

const MultiStockSummary = ({
  projectData = {},
  selectedWidth = "total",
  setSelectedWidth,
}) => {
  const {
    project_summary = [],
    name: project_name = "N/A",
    project_client_name = "N/A",
    number_of_files = 0,
    location = "N/A",
    status = "N/A",
    expected_due_date = "N/A",
  } = projectData;

  const selectedSummary = useMemo(
    () =>
      project_summary.find((s) => s.width === selectedWidth) ||
      project_summary.find((s) => s.width === "total") || {
        waste_perc: 0,
        quantity: 0,
        unique_sizes: 0,
        wastage: 0,
        weight: 0,
      },
    [project_summary, selectedWidth]
  );

  const pieData = useMemo(
    () => [
      {
        id: 0,
        value: 100 - (selectedSummary.waste_perc || 0),
        label: "Utilized",
      },
      { id: 1, value: selectedSummary.waste_perc || 0, label: "Waste" },
    ],
    [selectedSummary.waste_perc]
  );

  // Process data for two quantities per width
  const { chartData, xLabels } = useMemo(() => {
    const widths = project_summary
      .filter((item) => item.width !== "total")
      .map((item) => `${item.width}mm`);

    // Collect all unique quantity_* keys (lengths)
    const uniqueLengths = Array.from(
      new Set(
        project_summary.flatMap((item) =>
          Object.keys(item)
            .filter((key) => key.startsWith("quantity_") && item[key] > 0)
            .map((key) => parseInt(key.split("_")[1]))
        )
      )
    ).sort((a, b) => a - b);

    const colorPalette = [
      "#1976D2",
      "#FF5722",
      "#4CAF50",
      "#E91E63",
      "#FFC107",
      "#9C27B0",
      "#00BCD4",
      "#8BC34A",
      "#FF9800",
      "#795548",
    ];

    // Map each width to a series of values corresponding to the unique lengths
    const seriesData = uniqueLengths.map((length) => ({
      data: project_summary
        .filter((item) => item.width !== "total")
        .map((item) => item[`quantity_${length}`] || 0),
      label: `${length}mm`, // Label remains consistent
      color: colorPalette[uniqueLengths.indexOf(length) % colorPalette.length],
    }));

    return {
      chartData: seriesData,
      xLabels: widths,
      uniqueLabels: uniqueLengths.map((l) => `${l}mm`),
    };
  }, [project_summary]);

  const projectDetails = [
    ["Client Name", project_client_name],
    ["No. of Files", number_of_files],
    ["Location", location],
    ["Status", status],
    ["Due Date", expected_due_date],
  ];

  const metricDetails = useMemo(() => {
    // Extract and format all "quantity_*" keys dynamically
    const quantityMetrics = Object.entries(selectedSummary)
      .filter(
        ([key]) => key.startsWith("quantity_") && selectedSummary[key] > 0
      )
      .sort(
        (a, b) => parseInt(a[0].split("_")[1]) - parseInt(b[0].split("_")[1])
      ) // Sort by size
      .map(([key, value]) => [
        `Total No. of ${key.split("_")[1]}mm Bars`,
        value,
      ]);

    return [
      ...quantityMetrics,
      ["Total (no. of unique sizes)", selectedSummary.unique_sizes],
      ["Wastage", `${selectedSummary.waste_perc}%`],
      ["Weight In Tonnes", selectedSummary.weight],
      ["Wastage In Tonnes", selectedSummary.wastage],
    ];
  }, [selectedSummary]);

  if (!projectData || projectData.project_summary.length === 0) {
    return (
      <div className="mt-20 flex items-center justify-center">
        Project multi stock summary not available.
      </div>
    );
  }

  return (
    <div className="p-5 mt-5">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        {/* Left Column - Project Details */}
        <ProjectDetails details={projectDetails} />

        {/* Right Column - Metrics */}
        <MetricDetails
          title="Metrics"
          metrics={metricDetails}
          selectedWidth={selectedWidth}
          onChange={(e) => setSelectedWidth(e.target.value)}
          options={project_summary}
        />

        {/* Bar Chart */}
        <div className=" bg-white p-4 pb-0 rounded-md border border-solid border-slate-300 ">
          <div className="text-center font-medium mb-1 text-gray-800 ">
            No. of Bars per Rebar Diameter(mm)
          </div>
          <div className="text-center text-sm mb-4 text-gray-600 ">
            (Hover over the chart to see the values)
          </div>

          <div className="flex justify-center">
            <BarChart
              series={chartData}
              xAxis={[
                {
                  data: xLabels,
                  scaleType: "band",
                  categoryGapRatio: 0.2,
                  barGapRatio: 0,
                  tickPlacement: "middle",
                  tickLabelPlacement: "middle",
                },
              ]}
              grid={{ horizontal: true }}
              height={400}
              width={700}
              // slotProps={{ legend: { hidden: true } }}
            />
          </div>
        </div>

        {/* Pie Chart */}
        <div className="bg-white p-4 rounded-md border border-solid border-slate-300">
          <div className="text-center mb-1 font-medium text-gray-800 ">
            {selectedWidth === "total" ? "Overall" : `${selectedWidth}mm`}{" "}
            (Pieces) : Utilized vs Waste(%)
          </div>
          <div className="text-center text-sm mb-4 text-gray-600 ">
            (Hover over the chart to see the values)
          </div>
          <div className="flex justify-center">
            <PieChart
              colors={["#1976D2", "#EF4444"]}
              series={[
                {
                  data: pieData,
                  cx: 90,
                  highlightScope: { faded: "global", highlight: "item" },
                  valueFormatter: (item) => `${item.value}%`,
                  // arcLabel: (item) => `${item.value}%`,
                },
              ]}
              width={300}
              height={300}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiStockSummary;
