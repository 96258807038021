import React, { useMemo } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { Select, MenuItem } from "@mui/material";

const ProjectDetails = ({ details }) => (
  <div className="pt-4 pb-5 px-6 bg-gray-100 rounded-md">
    <span className="font-semibold text-xl leading-10">Project Details</span>
    <div className="space-y-2 mt-4">
      {details.map(([label, value]) => (
        <div className="flex justify-between" key={label}>
          <span>{label}:</span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  </div>
);

const MetricDetails = ({
  title,
  metrics,
  selectedWidth,
  onChange,
  options,
}) => (
  <div className="pt-4 pb-5 px-6 bg-gray-100 rounded-md">
    <div className="flex justify-between items-center mb-4">
      <span className="font-semibold text-xl">
        {selectedWidth === "total" ? "Overall" : `${selectedWidth}mm`} (Pieces)
      </span>
      <Select
        value={selectedWidth}
        onChange={onChange}
        className="h-10 bg-white"
        size="small"
        autoWidth
        displayEmpty
      >
        {options.map((item) => (
          <MenuItem key={item.width || "default"} value={item.width}>
            {item.width === "total" ? "Overall" : `${item.width}mm`}
          </MenuItem>
        ))}
      </Select>
    </div>
    <div className="space-y-2">
      {metrics.map(([label, value]) => (
        <div className="flex justify-between" key={label}>
          <span>{label}:</span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  </div>
);

const StockSummary = ({
  projectData = {},
  selectedWidth = "total",
  setSelectedWidth,
}) => {
  const {
    project_summary = [],
    name: project_name = "N/A",
    project_client_name = "N/A",
    number_of_files = 0,
    location = "N/A",
    status = "N/A",
    expected_due_date = "N/A",
  } = projectData;

  const selectedSummary = useMemo(
    () =>
      project_summary.find((s) => s.width === selectedWidth) ||
      project_summary.find((s) => s.width === "total") || {
        waste_perc: 0,
        quantity: 0,
        unique_sizes: 0,
        wastage: 0,
        weight: 0,
      },
    [project_summary, selectedWidth]
  );

  const pieData = useMemo(
    () => [
      {
        id: 0,
        value: 100 - (selectedSummary.waste_perc || 0),
        label: "Utilized",
      },
      { id: 1, value: selectedSummary.waste_perc || 0, label: "Waste" },
    ],
    [selectedSummary.waste_perc]
  );

  const barData = useMemo(
    () =>
      project_summary
        .filter((item) => item.width !== "total" && item.width && item.quantity)
        .map((item) => ({
          width: `${item.width}mm`,
          bars: item.quantity,
        })),
    [project_summary]
  );

  const projectDetails = [
    ["Client Name", project_client_name],
    ["No. of Files", number_of_files],
    ["Location", location],
    ["Status", status],
    ["Due Date", expected_due_date],
  ];

  const metricDetails = [
    ["Total No. of Bars", selectedSummary.quantity],
    ["Total (no. of unique sizes)", selectedSummary.unique_sizes],
    ["Wastage", `${selectedSummary.waste_perc}%`],
    ["Weight In Tonnes", selectedSummary.weight],
    ["Wastage In Tonnes", selectedSummary.wastage],
  ];

  return (
    <div className="p-5 mt-5">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        {/* Left Column - Project Details */}
        <ProjectDetails details={projectDetails} />

        {/* Right Column - Metrics */}
        <MetricDetails
          title="Metrics"
          metrics={metricDetails}
          selectedWidth={selectedWidth}
          onChange={(e) => setSelectedWidth(e.target.value)}
          options={project_summary}
        />

        {/* Bar Chart */}
        <div className=" bg-white p-4 rounded-md border border-solid border-slate-300 ">
          <div className="text-center font-medium mb-1 text-gray-800 ">
            No. of Bars per Rebar Diameter(mm)
          </div>
          <div className="text-center text-sm mb-4 text-gray-600 ">
            (Hover over the chart to see the values)
          </div>

          <div className="flex justify-center">
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: barData.map((item) => item.width),
                  tickPlacement: "middle",
                  tickLabelPlacement: "middle",
                },
              ]}
              series={[
                {
                  data: barData.map((item) => item.bars),
                  label: "No. of bars",
                  color: "#1976D2",
                },
              ]}
              grid={{ horizontal: true }}
              width={500}
              height={320}
            />
          </div>
        </div>

        {/* Pie Chart */}
        <div className="bg-white p-4 rounded-md border border-solid border-slate-300">
          <div className="text-center mb-1 font-medium text-gray-800 ">
            {selectedWidth === "total" ? "Overall" : `${selectedWidth}mm`}{" "}
            (Pieces) : Utilized vs Waste(%)
          </div>
          <div className="text-center text-sm mb-4 text-gray-600 ">
            (Hover over the chart to see the values)
          </div>
          <div className="flex justify-center">
            <PieChart
              colors={["#1976D2", "#EF4444"]}
              series={[
                {
                  data: pieData,
                  cx: 90,
                  highlightScope: { faded: "global", highlight: "item" },
                  valueFormatter: (item) => `${item.value}%`,
                  // arcLabel: (item) => `${item.value}%`,
                },
              ]}
              width={300}
              height={300}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockSummary;
